import { Injectable } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { Firestore, doc, getDoc, updateDoc } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Action, State, StateContext } from "@ngxs/store";
import { setDoc } from "firebase/firestore";
import { IUser } from "src/app/core/interfaces";
import {
  UserStateModel,
  generateDefaultUserState,
} from "./user-model.interface";
import { User } from "./user.actions";

@State<UserStateModel>({
  name: "user",
  defaults: generateDefaultUserState(),
})
@Injectable()
export class UserState {
  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private router: Router
  ) {}

  @Action(User.Get)
  async getUser(ctx: StateContext<UserStateModel>, action: User.Get) {
    const userUid = action.uid;
    const userDoc = doc(this.firestore, "user", userUid);

    const userSnapshot = await getDoc(userDoc);
    if (userSnapshot.exists()) {
      const userData = userSnapshot.data() as IUser;
      // Patch state
      ctx.patchState({ ...userData, id: userSnapshot.id });
    } else {
      // Handle case where user does not exist
      this.auth.signOut();
      this.router.navigate(["/auth/login"]);
      throw new Error("User does not exist!");
    }
  }

  @Action(User.Reset)
  reset(ctx: StateContext<UserStateModel>) {
    ctx.setState(generateDefaultUserState());
  }

  @Action(User.SetQuickStartState)
  async setQuickStartState(
    ctx: StateContext<UserStateModel>,
    action: User.SetQuickStartState
  ) {
    const userUid = ctx.getState().id;
    if (!userUid) {
      return;
    }
    const quickStartState = action.quickStartState;

    const userDoc = doc(this.firestore, "user", userUid);
    await updateDoc(userDoc, { quickStartState });
    ctx.patchState({ quickStartState });
  }

  @Action(User.UpdateConsentData)
  async updateConsentData(
    ctx: StateContext<UserStateModel>,
    action: User.UpdateConsentData
  ) {
    const user = ctx.getState();

    const shouldUpdateConsentData =
      action.consentFirstName !== user.consentFirstName ||
      action.consentLastName !== user.consentLastName;

    if (!shouldUpdateConsentData) return;

    const userDoc = doc(this.firestore, "user", user.id);

    await setDoc(userDoc, {
      ...user,
      ...action,
    });

    ctx.patchState({
      consentFirstName: action.consentFirstName,
      consentLastName: action.consentLastName,
    });
  }

  @Action(User.Update)
  async updateUser(_ctx: StateContext<UserStateModel>, action: User.Update) {
    const { updatedUser } = action;

    if (!updatedUser || !updatedUser.id) {
      console.error("User ID is required to update user data");
      return;
    }

    const userModel: IUser = {
      ...updatedUser,
    };

    const userDoc = doc(this.firestore, "user", updatedUser.id);

    try {
      await setDoc(userDoc, userModel);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  }
}
