import { GovernmentIdType } from "../interfaces";

export const stateOptions = [
  { value: "AL", viewValue: "Alabama" },
  { value: "AK", viewValue: "Alaska" },
  { value: "AZ", viewValue: "Arizona" },
  { value: "AR", viewValue: "Arkansas" },
  { value: "CA", viewValue: "California" },
  { value: "CO", viewValue: "Colorado" },
  { value: "CT", viewValue: "Connecticut" },
  { value: "DE", viewValue: "Delaware" },
  { value: "FL", viewValue: "Florida" },
  { value: "GA", viewValue: "Georgia" },
  { value: "HI", viewValue: "Hawaii" },
  { value: "ID", viewValue: "Idaho" },
  { value: "IL", viewValue: "Illinois" },
  { value: "IN", viewValue: "Indiana" },
  { value: "IA", viewValue: "Iowa" },
  { value: "KS", viewValue: "Kansas" },
  { value: "KY", viewValue: "Kentucky" },
  { value: "LA", viewValue: "Louisiana" },
  { value: "ME", viewValue: "Maine" },
  { value: "MD", viewValue: "Maryland" },
  { value: "MA", viewValue: "Massachusetts" },
  { value: "MI", viewValue: "Michigan" },
  { value: "MN", viewValue: "Minnesota" },
  { value: "MS", viewValue: "Mississippi" },
  { value: "MO", viewValue: "Missouri" },
  { value: "MT", viewValue: "Montana" },
  { value: "NE", viewValue: "Nebraska" },
  { value: "NV", viewValue: "Nevada" },
  { value: "NH", viewValue: "New Hampshire" },
  { value: "NJ", viewValue: "New Jersey" },
  { value: "NM", viewValue: "New Mexico" },
  { value: "NY", viewValue: "New York" },
  { value: "NC", viewValue: "North Carolina" },
  { value: "ND", viewValue: "North Dakota" },
  { value: "OH", viewValue: "Ohio" },
  { value: "OK", viewValue: "Oklahoma" },
  { value: "OR", viewValue: "Oregon" },
  { value: "PA", viewValue: "Pennsylvania" },
  { value: "RI", viewValue: "Rhode Island" },
  { value: "SC", viewValue: "South Carolina" },
  { value: "SD", viewValue: "South Dakota" },
  { value: "TN", viewValue: "Tennessee" },
  { value: "TX", viewValue: "Texas" },
  { value: "UT", viewValue: "Utah" },
  { value: "VT", viewValue: "Vermont" },
  { value: "VA", viewValue: "Virginia" },
  { value: "WA", viewValue: "Washington" },
  { value: "WV", viewValue: "West Virginia" },
  { value: "WI", viewValue: "Wisconsin" },
  { value: "WY", viewValue: "Wyoming" },
  // US Territories
  { value: "AS", viewValue: "American Samoa" },
  { value: "DC", viewValue: "District of Columbia" },
  { value: "FM", viewValue: "Federated States of Micronesia" },
  { value: "GU", viewValue: "Guam" },
  { value: "MH", viewValue: "Marshall Islands" },
  { value: "MP", viewValue: "Northern Mariana Islands" },
  { value: "PW", viewValue: "Palau" },
  { value: "PR", viewValue: "Puerto Rico" },
  { value: "VI", viewValue: "U.S. Virgin Islands" },
  { value: "AE", viewValue: "Armed Forces Europe/Middle-East/Canada" },
  { value: "AA", viewValue: "Armed Forces Americas (except Canada)" },
  { value: "AP", viewValue: "Armed Forces Pacific" },
];

export const provinceOptions = [
  { value: "AB", viewValue: "Alberta" },
  { value: "BC", viewValue: "British Columbia" },
  { value: "MB", viewValue: "Manitoba" },
  { value: "NB", viewValue: "New Brunswick" },
  { value: "NL", viewValue: "Newfoundland and Labrador" },
  { value: "NS", viewValue: "Nova Scotia" },
  { value: "NT", viewValue: "Northwest Territories" },
  { value: "NU", viewValue: "Nunavut" },
  { value: "ON", viewValue: "Ontario" },
  { value: "PE", viewValue: "Prince Edward Island" },
  { value: "QC", viewValue: "Quebec" },
  { value: "SK", viewValue: "Saskatchewan" },
  { value: "YT", viewValue: "Yukon" },
];

export const usTerritories = [
  "American Samoa",
  "District of Columbia",
  "Federated States of Micronesia",
  "Guam",
  "Marshall Islands",
  "Northern Mariana Islands",
  "Palau",
  "Puerto Rico",
  "U.S. Virgin Islands",
];

export const countries = [
  "United States",
  "American Samoa",
  "Guam",
  "Northern Mariana Islands",
  "Puerto Rico",
  "U.S. Virgin Islands",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const tribes = [
  "Aleut",
  "Algonquin",
  "Apache",
  "Arapaho",
  "Blackfeet",
  "Cherokee Nation",
  "Cheyenne",
  "Chippewa",
  "Choctaw",
  "Cree",
  "Crow",
  "Dakota",
  "Haida",
  "Hopi",
  "Huron",
  "Inuit",
  "Iroquois Confederacy",
  "Lakota",
  "Miwok",
  "Mohican",
  "Nakota (part of the Sioux Nation)",
  "Navajo Nation",
  "Ojibwe",
  "Osage",
  "Pueblo",
  "Salish",
  "Seminole",
  "Shoshone",
  "Sioux",
  "Tohono O'odham",
  "Tlingit",
  "Ute",
  "Yakama",
  "Other",
];

export const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

export const idTypes: GovernmentIdType[] = [
  "Drivers License",
  "US Passport",
  "Foreign Passport",
  "State/Local/Tribe issued ID",
];

export const taxIdTypes = ["EIN", "SSN/ITIN", "Foreign"];

export const prohibitedWords = [
  "aka",
  "dba",
  "nmn",
  "none",
  "n/a",
  "unknown",
  "not applicable",
  "same",
  "same as above",
  "see above",
  "t/a",
  "various",
  "xx",
  "xxx",
];

export const addressTypes = ["business", "residential"];

export const errorMessages = {
  entityFinCENId: "12 digits, starts with 2",
  ownerFinCENId: "12 digits, starts with 3",
};

export const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]+$/;
