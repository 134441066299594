import {
  AfterViewInit,
  Component,
  OnInit,
  inject,
  isDevMode,
} from "@angular/core";
import { Auth, authState, multiFactor } from "@angular/fire/auth";
import { Firestore, doc, getDoc } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { ChildrenOutletContexts, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import {
  Actions,
  Store,
  ofActionCompleted,
  ofActionSuccessful,
} from "@ngxs/store";
import { BehaviorSubject, Observable, filter, takeUntil, tap } from "rxjs";
import {
  ActionDialogComponent,
  ActionDialogData,
} from "./core/components/action-dialog/action-dialog.component";
import { BaseComponent } from "./core/components/base/base.component";
import { BreakpointsService } from "./core/services/breakpoints.service";
import { RouteTitleService } from "./core/services/route-title.service";
import { AppStateModel } from "./state/app-state/app-model.interface";
import { App } from "./state/app-state/app.actions";
import { SecureFile } from "./state/secure-file-state/secure-file.actions";
import { SecureProStateModel } from "./state/secure-pro-state/secure-pro-model.interface";
import {
  Organization,
  Owner,
} from "./state/secure-pro-state/secure-pro.actions";
import { UserStateModel } from "./state/user-state/user-model.interface";
import { User } from "./state/user-state/user.actions";
import { TwoFactorDialogComponent } from "./modules/auth/components/two-factor-dialog/two-factor-dialog.component";
import { AuthService } from "./core/services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [],
})
export class AppComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private router: Router = inject(Router);
  private store: Store = inject(Store);
  private actions: Actions = inject(Actions);
  private auth: Auth = inject(Auth);
  private firestore: Firestore = inject(Firestore);
  private dialog: MatDialog = inject(MatDialog);
  private routeTitleService: RouteTitleService = inject(RouteTitleService);
  private swUpdate: SwUpdate = inject(SwUpdate);
  private contexts: ChildrenOutletContexts = inject(ChildrenOutletContexts);
  private breakpointService: BreakpointsService = inject(BreakpointsService);
  private authService: AuthService = inject(AuthService);

  title = "Secure Compliance";
  authState$ = authState(this.auth);
  waitingForUserSource$ = new BehaviorSubject<boolean>(true);
  waitingForUser$ = this.waitingForUserSource$.asObservable();
  org$ = this.store.select(
    (state) => (state.securePro as SecureProStateModel).org
  );
  sidebarOpen$ = this.store.select(
    (state) => (state.app as AppStateModel).sidebarOpen
  );
  user$ = this.store.select((state) => state.user as UserStateModel);
  waitingToUpdate$ = this.store.select(
    (state) => (state.app as AppStateModel).waitingToUpdate
  );
  isHandset$: Observable<boolean> = this.breakpointService.isHandsetGlobal$();

  quickStartOpen = false;
  sidebarOpen = false;
  isHandset = false;

  ngOnInit() {
    this.routeTitleService.initializeTitleService();

    if (!isDevMode()) {
      this.registerServiceWorkerUpdates();
    }
    this.registerUserInitialization();
    this.register2fa();

    this.actions
      .pipe(
        ofActionSuccessful(SecureFile.GetEntities),
        takeUntil(this.isDestroyed$),
        tap(() => {
          this.waitingForUserSource$.next(false);
        })
      )
      .subscribe();

    // Track some values as synchronous variables
    this.sidebarOpen$.pipe(takeUntil(this.isDestroyed$)).subscribe((value) => {
      this.sidebarOpen = value;
    });

    this.isHandset$.pipe(takeUntil(this.isDestroyed$)).subscribe((value) => {
      this.isHandset = value;
    });

    // Open a dialog with a recommendation to add 2FA, if the secure-file user does not have 2FA, has a verified mail,
    // and has not rejected this recommendation before
    this.user$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((user) => user.userType === "secure-file" && !user.skipTwoFactor)
      )
      .subscribe(() => {
        if (
          this.authService.emailVerified &&
          !this.authService.hasMultiFactor
        ) {
          this.dialog.open(TwoFactorDialogComponent);
        }
      });
  }

  ngAfterViewInit() {
    this.authState$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap(async (user) => {
          const inSignUpFlow = this.router.url.includes("auth");
          if (user) {
            // Query Firebase to see if this user has a userDoc
            const userDoc = doc(this.firestore, "user", user?.uid);

            const userSnapshot = await getDoc(userDoc);
            if (userSnapshot.exists()) {
              this.waitingForUserSource$.next(true);
              this.store.dispatch(new User.Get(user.uid));

              let providerId;
              try {
                providerId = user.providerData.find(
                  (provider) => provider.providerId === "password"
                )?.providerId;
              } catch (error) {
                providerId = "";
              }
              if (providerId === "password" && !user.emailVerified) {
                this.router.navigate(["auth/email-verification"]);
              }
            } else {
              this.waitingForUserSource$.next(false);
            }
          } else if (inSignUpFlow || !user) {
            this.waitingForUserSource$.next(false);
          }
        })
      )
      .subscribe();
    this.waitingForUser$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((waiting) => !waiting)
      )
      .subscribe((waiting) => {
        this.authService.setStoreInitialized(!waiting);
      });
  }

  private registerServiceWorkerUpdates() {
    this.swUpdate.versionUpdates
      .pipe(filter((evt) => evt.type === "VERSION_READY"))
      .subscribe(() => {
        this.swUpdate.activateUpdate().then(() => {
          this.dialog
            .open(ActionDialogComponent, {
              data: {
                title: "Update Available",
                message: "A new version of the app is available. Reload?",
                positiveAction: false,
                negativeActionButtonText: "Reload",
              },
            })
            .afterClosed()
            .subscribe(() => {
              window.location.reload();
            });
        });
      });
  }

  private registerUserInitialization() {
    this.actions
      .pipe(
        ofActionCompleted(User.Get),
        tap((action) => {
          // If the user doesn't exist, ask the user if they want to create an account
          const userNotFound = action.result.successful === false;
          if (userNotFound) {
            this.store.dispatch(new App.Reset());
            this.waitingForUserSource$.next(false);

            const dialogData: ActionDialogData = {
              title: "We couldn't find your account",
              message: `Would you like to create an account?`,
              positiveAction: true,
              positiveActionButtonText: "Sign me up!",
              negativeActionButtonText: "No thanks",
            };

            const dialogRef = this.dialog.open(ActionDialogComponent, {
              width: "350px",
              data: dialogData,
            });

            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.router.navigate(["/auth/sign-up"]);
              }
            });
            return;
          }
          const user = this.store.selectSnapshot(
            (state) => state.user as UserStateModel
          );
          this.startStateSetup(user);
        })
      )
      .subscribe();
  }

  private register2fa() {
    // Wait for the user to be loaded from the store
    this.actions
      .pipe(
        ofActionSuccessful(Owner.GetPublicOwnersSuccess),
        // Take 1 because we only want to do this once
        takeUntil(this.isDestroyed$),
        tap(() => {
          this.waitingForUserSource$.next(false);

          // If the user doesn't have a phone number and hasn't skipped 2FA, redirect them to the 2FA setup page
          const userState = this.store.selectSnapshot(
            (state) => state.user as UserStateModel
          );
          const userAuthObject = this.auth.currentUser;
          if (
            userState &&
            userAuthObject &&
            !userState.skipTwoFactor &&
            multiFactor(userAuthObject).enrolledFactors.length === 0 &&
            userAuthObject.providerData.find(
              (provider) => provider.providerId === "password"
            )
          ) {
            // this.router.navigate(["auth/two-factor-setup"]);
            return;
          }
        })
      )
      .subscribe();
  }

  private startStateSetup(user: UserStateModel) {
    if (
      !document.URL.includes("payments") &&
      !document.URL.includes("filing/") &&
      !document.URL.includes("sign-up") &&
      !document.URL.includes("email-verification") &&
      !document.URL.includes("secure-pro/owners/") &&
      !document.URL.includes("entities/")
    ) {
      this.store.dispatch(new App.OpenSidebar());
    }
    if (user.userType === "secure-pro" && user.orgId) {
      this.store.dispatch(new Organization.Get(user.orgId));
    } else if (user.userType === "secure-file") {
      this.store.dispatch(new SecureFile.GetEntities());
    }
  }

  async settingsRoute() {
    this.router.navigate(["secure-pro/settings"]);
  }

  async signOut() {
    try {
      // Clear any browser storage
      await this.auth.signOut();
      this.router.navigate(["/"]);
      // Reload the app to clear all state
      location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  reloadApp() {
    window.location.reload();
  }

  getRouteAnimationData() {
    return this.contexts.getContext("primary")?.route?.snapshot?.data?.[
      "animation"
    ];
  }

  navigateMobile() {
    if (this.sidebarOpen && this.isHandset) {
      this.store.dispatch(new App.CloseSidebar());
    }
  }

  backToDashboard() {
    this.user$.pipe(takeUntil(this.isDestroyed$)).subscribe((user) => {
      if (user.userType === "secure-pro") {
        this.router.navigate(["/secure-pro/dashboard"]);
      } else if (user.userType === "secure-file") {
        this.router.navigate(["/secure-file/dashboard"]);
      }
    });
  }
}
